import React, { useEffect, useRef, useState, useCallback } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, LocationAutocompleteInputField, Button, FieldTextInput, OutsideClickHandler } from '../../components';
import css from './EditListingLocationForm.module.css';
import { locationApi } from '../../util/api';
import { types as sdkTypes } from '../../util/sdkLoader';

const { LatLng } = sdkTypes;

const LocationSearchField = ({ onLocationChange, formApi, intl }) => {
  const [predictions, setPredictions] = useState([]);
  const [countryInputFocused, setCountryInputFocused] = useState(false);
  const [countryInput, setCountryInput] = useState('');
  const countryDropdownRef = useRef(null);

  const fetchPredictions = useCallback(async query => {
    if (!query) {
      setPredictions([]);
      return;
    }
    try {
      const response = await locationApi({ query });
      setPredictions(response?.results || []);
    } catch (error) {
      console.error('Error fetching predictions:', error);
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => fetchPredictions(countryInput), 300); // Debounce API calls
    return () => clearTimeout(timeout);
  }, [countryInput, fetchPredictions]);

  const handleInputChange = e => {
    const value = e.target.value;
    setCountryInput(value);
    formApi.change('address', value);
  };

  const handlePredictionClick = ({ formatted_address, geometry }) => {
    if (!geometry) return;
    
    const location = {
      address: formatted_address,
      origin: new LatLng(geometry.location.lat, geometry.location.lng),
    };
    
    setCountryInput(formatted_address);
    formApi.change('address', formatted_address);
    formApi.change('location', { selectedPlace: location });
    setPredictions([]);
    setCountryInputFocused(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setCountryInputFocused(false)}>
      <div className={css.addressInput}>
        <FieldTextInput
          id="address"
          name="address"
          type="text"
          autoComplete="off"
          className={css.mobileInputRoot}
          label={intl.formatMessage({ id: 'EditListingLocationForm.address' })}
          placeholder="Destination"
          onFocus={() => setCountryInputFocused(true)}
          onChange={handleInputChange}
          value={countryInput}
        />
        {predictions.length > 0 && countryInputFocused && (
          <ul className={css.dropdown} ref={countryDropdownRef}>
            {predictions.map((prediction, index) => (
              <li
                key={index}
                className={css.predictionItem}
                onClick={() => handlePredictionClick(prediction)}
              >
                {prediction.formatted_address}
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    keepDirtyOnReinitialize
    render={({ className, disabled, ready, handleSubmit, intl, invalid, pristine, saveActionMsg, updated, updateInProgress, fetchErrors, form }) => {
      const { updateListingError, showListingsError } = fetchErrors || {};
      const submitDisabled = invalid || disabled || updateInProgress;
      const submitReady = (updated && pristine) || ready;

      return (
        <Form className={classNames(css.root, className)} onSubmit={handleSubmit}>
          {updateListingError && (
            <p className={css.error}>
              <FormattedMessage id="EditListingLocationForm.updateFailed" />
            </p>
          )}
          {showListingsError && (
            <p className={css.error}>
              <FormattedMessage id="EditListingLocationForm.showListingFailed" />
            </p>
          )}

          <div className={css.box}>
            <div className={css.locationGoogleBox}>
              <LocationSearchField formApi={form} onLocationChange={() => {}} intl={intl} />
            </div>
            <FieldTextInput
              className={css.inputBox}
              type="text"
              name="building"
              id="building"
              label={intl.formatMessage({ id: 'EditListingLocationForm.building' })}
              placeholder={intl.formatMessage({ id: 'EditListingLocationForm.buildingPlaceholder' })}
            />
          </div>

          <Button className={css.submitButton} type="submit" inProgress={updateInProgress} disabled={submitDisabled} ready={submitReady}>
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
